@import 'styles/common';

body {
    background-color: $rich-black-fogra-29;
    padding: 0;
    margin: 0;
}

.home {
    display: flex;
    justify-content: space-around;
    height: 90vh;
    align-items: center;

    &__banner {
        display: flex;
        justify-content: space-around;
        background-color: $oxford-blue;
        width: 100%;
        padding: 10px;
        box-shadow: 2px 2px black;

    }

    &__content {
        display: flex;
        align-items: center;
        user-select: none;


        &__logo {
            width: 100px;

            @media (max-width: 600px) {
                width: 50px;
            }

            img {
                width: 100%;
            }
        }

        &__name {
            margin-left: 20px;
            font-family: $lobster;
            font-size: 50px;
            color: $shadow-blue;
            text-shadow: -1px -1px 0px $platinum, 1px 1px 0px $oxford-blue;

            @media (max-width: 600px) {
                font-size: 32px;
            }
        }
    }
}
